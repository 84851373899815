.Loader__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.Loader {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 3px solid #f3f3f3;
  border-top:4px solid orange;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
